<template>
  <div class="my-collect">
    <navbar title="我的收藏" />
    <van-tabs v-model="active" @click="initialList" animated background="#FAFAFA" color="#00D3C4" line-width="60" title-active-color="#00D3C4" title-inactive-color="#444">

      <van-tab title="新房">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50" v-if="buildingList && buildingList.length > 0">
          <div class="house-box">

            <div class="box-item" v-for="item in buildingList" :key="item.id">
              <building-item :item="item" :types="1" />
            </div>
          </div>
        </van-list>

        <van-empty v-else description="您还没有收藏任何新房" />
      </van-tab>
      <van-tab title="二手房">

        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50" v-if="houseList && houseList.length > 0">
          <div class="house-box">
            <div class="box-item" v-for="item in houseList" :key="item.id">
              <house-item :item="item" :types="1" />
            </div>
          </div>
        </van-list>
        <van-empty v-else description="您还没有收藏任何二手房" />
      </van-tab>
      <van-tab title="视频">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50" v-if="videoList && videoList.length > 0">
          <div class="house-box">
            <div class="video-item" v-for="item in videoList" :key="item.id">
              <div class="video-left" @click="getVideo(item.id,item.houseId)">
                <p class="video-img"><img :src="item.pic" alt=""></p>
              </div>
              <div class="video-right" @click="getVideo(item.id,item.houseId)">
                <p class="video-title">{{item.title}}</p>
                <p class="video-count" v-if="item.playCount">{{item.playCount}}次播放</p>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty v-else description="您还没有收藏任何视频" />
      </van-tab>
      <van-tab title="资讯">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50" v-if="infoList && infoList.length > 0">
          <div class="house-box">
            <div class="info-item" v-for="item in infoList" :key="item.id" @click="goConsultDetail(item.id)">
              <div class="infoinfo-left">
                <p class="info-img"><img :src="item.pics" alt=""></p>
              </div>
              <div class="info-right">
                <p class="info-title" v-if="item.title">{{item.title|ellipsis}}</p>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty v-else description="您还没有收藏任何资讯" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import houseItem from "../../components/HouseItem.vue";
import buildingItem from "../../components/BuildingItem.vue";

export default {
  name: "myCollect",
  data() {
    return {
      active: 0,
      houseList: [],
      videoList: [],
      infoList: [],
      buildingList: [],
      pageNum: 0,
      pageSize: 10,
      loading: false,
      finished: false,
      hhh: [],
    };
  },
  components: {
    navbar,
    houseItem,
    buildingItem,
  },

  // mounted() {
  //   this.aaaaa();
  // },
  mounted() {
    this.getList();
  },
   filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 14) {
        return value.slice(0, 14) + '...'
      }
      return value
    }
  },
  methods: {
    onLoad() {
      this.loading = true;
      this.pageNum++;
      this.getList();
    },
    initialList() {
      this.pageNum = 1;
      this.getList();
    },
    getList() {
      const data = {
        active: this.active,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$post("user/getCollection", data).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          this.finished = false; //新房
          if (this.active == 0) {
            this.buildingList = res.data.Houses;
            let flag = false;
            //防止出现每页楼盘长度等于查询的楼盘长度
            if (
              res.data.Houses.length == res.pageSize &&
              res.data.count % this.pageSize == this.pageNum &&
              res.data.count / this.pageSize == 0
            ) {
              flag = true;
            }
            if (
              this.buildingList.length >= res.data.count ||
              res.data.Houses.length < this.pageSize ||
              flag
            ) {
              this.finished = true;
            }
          } else if (this.active == 1) {
            //二手房
            let flag = false;
            this.houseList = res.data.secondHouse;
            //防止出现每页楼盘长度等于查询的楼盘长度
            if (
              res.data.secondHouse.length == res.pageSize &&
              res.data.count % this.pageSize == this.pageNum &&
              res.data.count / this.pageSize == 0
            ) {
              flag = true;
            }
            if (
              this.houseList.length >= res.data.count ||
              res.data.secondHouse.length < this.pageSize ||
              flag
            ) {
              this.finished = true;
            }
          } else if (this.active == 2) {
            //视频
            // this.pageNum = 1;
            // this.finished = true;
            // this.videoList = res.data.houseVideo;
            // console.log(this.videoList);

            let flag = false;
            this.videoList = res.data.houseVideo;
            console.log(this.videoList);
            //防止出现每页楼盘长度等于查询的楼盘长度
            if (
              res.data.houseVideo.length == res.pageSize &&
              res.data.count % this.pageSize == this.pageNum &&
              res.data.count / this.pageSize == 0
            ) {
              flag = true;
            }
            if (
              this.videoList.length >= res.data.count ||
              res.data.houseVideo.length < this.pageSize ||
              flag
            ) {
              this.finished = true;
            }
          } else if (this.active == 3) {
            let flag = false;
            this.infoList = res.data.houseInformation;
            //防止出现每页楼盘长度等于查询的楼盘长度
            if (
              res.data.houseInformation.length == res.pageSize &&
              res.data.count % this.pageSize == this.pageNum &&
              res.data.count / this.pageSize == 0
            ) {
              flag = true;
            }
            if (
              this.infoList.length >= res.data.count ||
              res.data.houseInformation.length < this.pageSize ||
              flag
            ) {
              this.finished = true;
            }
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    goConsultDetail(id) {
      this.$router.push({
        path: "/ConsultDetail",
        query: {
          id: id,
        },
      });
    },
    getVideo(id, houseid) {
      console.log("获取我的视频id" + id)
      this.$router.push({
        path: "/videoDetail",
        query: {
          id: id,
          houseid: id
        },
      });
    }


  },
};
</script>
<style lang="less" scoped>
.my-collect {
  .house-box {
    padding: 0 15px;
    background: #fff;
    border-top: 1px solid #ddd;
  }
  .info-item {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    .info-img {
      img {
        width: 120px;
        height: 80px;
      }
    }
    .info-title {
      font-size: 16px;
      margin-left: 10px;
    }
  }
  .video-item {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    .video-left {
      .video-img {
        img {
          width: 120px;
          height: 80px;
        }
      }
    }
    .video-right {
      font-size: 16px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-content: space-between;
      margin-left: 10px;
      .video-title {
        color: #333333;
      }
      .video-count {
        color: #ff5742;
        margin-top: 39px;
      }
    }
  }

  .box-item {
    div {
      line-height: 1;
    }

    // display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
  }
}
</style>